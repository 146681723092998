import React from "react"
import Slider from "react-slick"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const CustomersSay = props => {
  const { id, title } = props
  const customerSectionSliderSettings = {
    className: "customer-says-slider",
    dots: true,
    centerMode: true,
    centerPadding: "0",
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    // slidesToScroll: 1,
    arrows: true,
    // autoplay: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          autoplay: true,
          pauseOnFocus: true,
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <>
      {/* <!-- What Our Customers Say --> */}
      <ScrollAnimation
        //offset={80}
        animateIn="fadeInUp"
        duration={1}
        animateOnce={true}
      >
        <section id={id} className="customers-says-section extra-bottom inner-padding">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="title">{title}</h3>
                <div className="customer-says-wrapper">
                  {/* <ul className="customer-says-slider" id="customer-says-sliders"> */}
                  <Slider {...customerSectionSliderSettings}>

                      <div className="customer-says-item">
                        <div className="customer-content">
                          <div className="review-text">
                            クラスはアットホームな雰囲気で自然な感じで英語に親しむことができ、子どももリラックスしてレッスンを受けています。  おすすめなのは定期的にあるイベントです。ゲームや製作などを通して英語や文化を学ぶことができ、毎年楽しそうに話してくれます。特に我が子はハロウィーンが大好きでダン先生たちの仮装にいつも期待しています！
                          </div>
                          <div className="author-name">
                            <span className="author">Kさん（小学生クラス）</span>
                          </div>
                        </div>
                      </div>
                      <div className="customer-says-item">
                        <div className="customer-content">
                          <div className="review-text">
                            ダン先生の気さくな人柄のおかげで、内気な娘も楽しく通っています。いつの間にか、看板や広告に載っている短文をサラッと読めるようになっていて、親が驚かされることも多いです。
                          </div>
                          <div className="author-name">
                            <span className="author">Iさん（小学生クラス）</span>
                          </div>
                        </div>
                      </div>

                      <div className="customer-says-item">
                        <div className="customer-content">
                          <div className="review-text">
                            ダン先生のはっきりとわかりやすい話し方のおかげで子どもの発音がネイティブっぽくなってきたように思います。家でも習った言葉をしゃべったり、楽しく通わせてもらっています。
                          </div>
                          <div className="author-name">
                            <span className="author">Mさん（園児クラス）</span>
                          </div>
                        </div>
                      </div>

                      <div className="customer-says-item">
                        <div className="customer-content">
                          <div className="review-text">
                            １クラスが少人数なので、しっかり学べて良いと思います。 ハロウィンやクリスマスのイベントもあり、子供もとても楽しんでいます。
                          </div>
                          <div className="author-name">
                            <span className="author">Aさん（小学生クラス）</span>
                          </div>
                        </div>
                      </div>

                  </Slider>
                  {/* </ul> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </ScrollAnimation>
    </>
  )
}

export default CustomersSay
