import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"
import { siteData } from "../../data/siteData"
import Img from "gatsby-image"



const FreeTrialLesson = props => {
  const { title, button } = props

  const data = useStaticQuery(graphql`
    query FreeTrialImagesQuery {
      trialImage1: file(relativePath: { eq: "global-trial-lesson-btn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Free Trial Lesson --> */}
      <ScrollAnimation
        //offset={80}
        animateIn="fadeInUp"
        duration={1}
        animateOnce={true}
      >
        <section className="free-trial-section inner-padding">
          <div className="container">
            <div className="row">
              <div className="free-trial-wrapper">
                <div className="free-trial-cols">
                  <Img
                    fluid={data['trialImage1'].childImageSharp.fluid}
                    className="free-trial-img" alt="体験レッスン受付中"
                  />
                </div>
                <div className="free-trial-cols">
                  <h3 className="title mb-5">{title}</h3>
                  <p>
                    体験レッスン受付中！英語初心者でも大丈夫！はじめの一歩は体験レッスンから♬いつでもお気軽にお問い合わせください♬
                  </p>
                  <ul className="inner-contact inlined mt-15">
                    <li>
                      <a href={`tel:${siteData.site.contactPhone}`}>
                        <i className="icon icon-call-blue" />
                        {siteData.site.contactPhone}
                      </a>
                    </li>
                    <li>
                      <a href={`mailto:${siteData.site.contactEmail}`}>
                        <i className="icon icon-envelope-blue" />
                        {siteData.site.contactEmail}
                      </a>
                    </li>
                  </ul>
                  <div className="button-wrap mt-15">
                    <Link to="/contact-us" className="btn btn-secondary">
                      {button}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ScrollAnimation>
    </>
  )
}

export default FreeTrialLesson
